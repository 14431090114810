import { render, staticRenderFns } from "./Container.vue?vue&type=template&id=b95a7ad6&scoped=true&"
import script from "./Container.vue?vue&type=script&lang=js&"
export * from "./Container.vue?vue&type=script&lang=js&"
import style0 from "./Container.vue?vue&type=style&index=0&id=b95a7ad6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b95a7ad6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!D:/project/private/vue-modern-resume/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VContent } from 'vuetify/lib'
import { VFadeTransition } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
installComponents(component, {VApp,VContainer,VContent,VFadeTransition,VFlex,VLayout})
