import { render, staticRenderFns } from "./Container.vue?vue&type=template&id=89c8efa2&scoped=true&"
import script from "./Container.vue?vue&type=script&lang=js&"
export * from "./Container.vue?vue&type=script&lang=js&"
import style0 from "./Container.vue?vue&type=style&index=0&id=89c8efa2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89c8efa2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!D:/project/private/vue-modern-resume/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VChip } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VProgressCircular } from 'vuetify/lib'
installComponents(component, {VAvatar,VCard,VCardText,VChip,VContainer,VFlex,VIcon,VLayout,VProgressCircular})
