import { render, staticRenderFns } from "./Primary.vue?vue&type=template&id=3178fd34&scoped=true&"
import script from "./Primary.vue?vue&type=script&lang=js&"
export * from "./Primary.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3178fd34",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!D:/project/private/vue-modern-resume/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VCardTitle } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VSwitch } from 'vuetify/lib'
import { VTimeline } from 'vuetify/lib'
import { VTimelineItem } from 'vuetify/lib'
installComponents(component, {VCard,VCardText,VCardTitle,VFlex,VLayout,VSwitch,VTimeline,VTimelineItem})
